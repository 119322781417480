<template>
  <v-btn :icon="isIconButton" @click="redirectToPage" color="accent" class="white--text" tile>
    <template v-if="isIconButton">
      <v-icon color="primary">
        {{ icon }}
      </v-icon>
    </template>
    <template v-else>
      {{ text }}
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'router-button',
  props: {
    text: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    redirect: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isIconButton: this.icon != null
    }
  },
  methods: {
    redirectToPage() {
      this.$router.push(this.redirect)
    }
  }


}
</script>

<style>
</style>
<template>
  <v-container fluid>
    <v-row class="my-10">
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
      <v-col>
        <payment-cancelled-card></payment-cancelled-card>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
    </v-row>
  </v-container>
</template>

<script>
import PaymentCancelledCard from '../../components/card/PaymentCancelledCard.vue'
export default {
    name: "payment-cancel-page",
    components: {
        PaymentCancelledCard
    },
}
</script>

<style>

</style>